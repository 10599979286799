import {Validator} from 'vee-validate'

const dictionary = {
  es: {
    attributes: {
      radio_shared_data: 'shared audiences'
    }
  }
}

Validator.localize(dictionary)

export default{}
